/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { withTranslation } from 'language/i18n';
import Step from './Step';
import StepMobile from './StepMobile';

const HowIsWork = ({ t, classNameForBG }) => {
  const ref = useRef();
  const [startAnimate, setStartAnimate] = useState(false);

  const handleScroll = () => {
    const boundingClientRect = ref.current?.getBoundingClientRect();
    if (!startAnimate && boundingClientRect?.top < 500 && boundingClientRect?.top > 300) {
      setStartAnimate(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const steps = [
    {
      svg: '/image/icons.svg#ic-checklist',
      title: t('membership'),
      text: `1-3 ${t('workDay')}`,
    },
    {
      svg: '/image/icons.svg#ic-degerleme',
      title: t('securityDeposit'),
      text: `3-5 ${t('workDay')}`,
    },
    {
      svg: '/image/icons.svg#ic-auction',
      title: t('onlineAuction'),
      text: `5-10 ${t('workDay')}`,
    },
    {
      svg: '/image/icons.svg#ic-wallet',
      title: t('payment'),
      text: `3-5 ${t('workDay')}`,
    },
    {
      svg: '/image/icons.svg#ic-transport',
      title: t('shipping'),
      text: `10-15 ${t('workDay')}`,
    },
  ];
  return (
    <section className={`${classNameForBG} js-section-how-to-work`}>
      <div className="section__header">
        <h2 className="section__header__title">{t('howCanYouBuyMachineWithIyimakina')}</h2>
        <p className="section__header__description">{t('howCanYouBuyMachineWithIyimakinaExplanation')}</p>
      </div>
      <div className="section__body">
        {/* STEP BAR */}
        <div
          className="im-step-bar im-step-bar--numbers d-none d-lg-flex"
          ref={ref}
        >
          {/* STEP */}
          {
            _.map(steps, (step, index) => {
              return <Step key={index} index={index} data={step} startAnimate={startAnimate} />;
            })
          }

        </div>
        {/* STEP BAR MOBILE */}
        <div className="im-step-bar-mobile d-flex d-lg-none">
          {
            _.map(steps, (step, index) => (
              <StepMobile key={index} index={index} data={step} />
            ))
          }
        </div>
      </div>
    </section>
  );
};

HowIsWork.propTypes = {
  t: PropTypes.func,
  classNameForBG: PropTypes.string,
};

export default withTranslation('common')(HowIsWork);
