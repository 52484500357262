import React from 'react';
import PropTypes from 'prop-types';

const StepMobile = ({ data, index }) => (
  <div className={`im-step-bar-mobile__step ${index === 2 ? 'im-step-bar-mobile__step--active' : ''}`}>
    <div className="im-step-bar-mobile__step__indicator">
      <svg>
        <use xlinkHref={data.svg} />
      </svg>
    </div>
    <h2 className="im-step-bar-mobile__step__title">
      {data.title}
    </h2>
    <div className="im-step-bar-mobile__step__description">
      <span>
        {' '}
        {data.title}
      </span>
    </div>
  </div>
);

StepMobile.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number,
};

export default StepMobile;
